<template>
  <div class="content-create-account">
    <div class="content-create-account-itens container">
      <div class="content-create-account-itens-header">
        <h1 v-if="segment == 'create'">{{ $t('register.create_an_account') }}</h1>
        <h1 v-else>{{ $t('register.edit_your_account') }}</h1>
      </div>
      <!-- FORM REGISTER AND EDIT -->
      <div class="col-sm-10 col-lg-6 mx-auto">
       <form-register @EventSegment="segment = $event"/>
      </div>
      <!---------------------------->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormRegister from "./FormRegister.vue";

export default {
  name: "Register",
  metaInfo: {
    title: "Registro",
  },
  components: {
    FormRegister
  },
  data() {
    return {
      segment: "create",
    };
  },
};
</script>

<style lang="scss">
.content-create-account {
  padding: 80px 0px;
  @media (max-width: 991px) {
    padding: 40px 0px;
  }
  &-itens {
    &-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
      h1 {
        font-size: 35px;
        font-family: "Font Bold";
        color: var(--main-color5);
        margin-bottom: 0;
        text-transform: uppercase;
        @media (max-width: 576px) {
          font-size: 28px;
        }
      }
    }
    &-body {
      padding-top: 45px;
      .content-elements {
        &-header {
          text-align: center;
          margin-bottom: 30px;
          h2 {
            font-size: 35px;
            font-family: "Font Bold";
            color: var(--main-color5);
            margin-bottom: 0;
            @media (max-width: 576px) {
              font-size: 28px;
            }
          }
        }
        &-body {
          .content-input {
            text-align: left;
            margin-bottom: 30px;
            label {
              display: block;
              font-size: 16px;
              font-family: "Font Regular";
              color: var(--main-color4);
              margin-bottom: 5px;
              line-height: 1;
            }
            input,
            select {
              height: 50px;
              width: 100%;
              border: 1px solid #ebebeb;
              background: #fff;
              outline: none;
              font-size: 16px;
              font-family: "Font Regular";
              color: var(--main-color4);
              padding-left: 20px;
              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
            }
            &-select {
              position: relative;
              img {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
              }
            }
            .help-block {
              font-size: 12px;
              font-family: "Font Light";
              color: var(--main-color4);
            }
          }
        }
      }
      .start-field > input[type="checkbox"]:checked:before {
        content: "";
        height: 100%;
        width: 100%;
        background: url("../../../../assets/img/checkout/check.png");
        background-position: center !important;
        background-repeat: no-repeat !important;
      }
      .start-field > input[type="checkbox"]:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px;
        border: 0;
      }
      .start-field > input[type="checkbox"] {
        position: relative;
        height: 30px;
        width: 30px;
        border-radius: 0px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0 !important;
        margin: 0;
        margin-right: 20px;
      }
      .start-field {
        display: flex;
        align-items: center;
        label {
          margin-bottom: 0 !important;
          a {
            text-decoration: underline;
            // margin: 0px 7px;
            transition: 500ms;
            &:hover,
            &:focus {
              cursor: pointer;
              opacity: 0.6;
              text-decoration: underline;
            }
          }
        }
      }
      .btn-create {
        height: 55px;
        width: 100%;
        background: var(--main-color5);
        border: 1px solid var(--main-color5);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: "Font Bold";
        color: var(--main-color);
        text-transform: uppercase;
        margin-bottom: 30px;
        transition: 500ms;
        &:hover,
        &:focus {
          background: var(--main-color);
          color: var(--main-color5);
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 16px;
          height: 50px;
        }
        @media (max-width: 359px) {
          font-size: 16px;
          height: 45px;
        }
      }
      .btns-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .btn-continue-shopping {
        display: flex;
        align-items: center;
        justify-content: center;
        background: 0;
        border: 0;
        font-size: 16px;
        font-family: "Font Bold";
        color: var(--main-color4);
        padding: 0;
        text-decoration: none;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }
        img {
          margin-right: 5px;
          @media (max-width: 576px) {
            max-width: 15px;
          }
        }
        svg {
          width: 20px;
          height: 20px;
          fill: var(--main-color4);
          margin-left: 5px;
          @media (max-width: 576px) {
            width: 15px;
            height: 15px;
          }
        }
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>